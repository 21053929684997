import { WSAPI } from './WSApi';

export default class RegAndPayApi extends WSAPI {
  getHtml (id, lang) {
    return this.instance.post(this.apiPath + '/RegNPay/GenForm', { key: id, lang: lang }).then((result) => {
      return result.data.ReturnValue.HtmlString;
    });
  }
  // eslint-disable-next-line no-useless-constructor
  private constructor () {
    super();
  }
  private static instance: RegAndPayApi;
  //* * 单例 */
  public static getInstance (): RegAndPayApi {
    if (RegAndPayApi.instance) { } else {
      RegAndPayApi.instance = new RegAndPayApi();
    }
    return RegAndPayApi.instance;
  }
}
